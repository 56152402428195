<script setup lang="ts">
    /*
        <OAttachments /> is a general-purpose component for displaying all attachments specified by passing in a data object.
        It relies on Spotlight.js to display every file in a carousel with some extra functionality like also viewing documents,
        downloading any attachment, 360-video support and image editing directly in the gallery.
    */

    import { ref, provide, computed, nextTick, onMounted, onBeforeUnmount } from 'vue';
    import THREE from 'three';
    import SpotLight from 'spotlight';
    import $t from "o365.modules.translate.ts";
    import { app } from 'o365.modules.configs.ts';
    import FileUtils from 'o365.modules.fileUtils.ts';
    import { loadCdnStyle } from 'o365.modules.helpers.js';
    import OImageEditor from 'o365.vue.components.ImageEditor.vue';
    import useErrorCapture from 'o365.vue.composables.ErrorCapture.ts';
    import MediaQueryManager from 'o365.vue.components.MediaQueryManager.ts';
    import { AttachmentsUserInterface, AttachmentsEventHandlers } from 'o365.vue.components.Attachments.helpers.ts';
    import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';

    const { getExtensionFromFileName, getFileNameFromUrl, getFileIconClasses, isImage, isVideo } = FileUtils;
    const { createLoadingOverlay, removeOverlay, createVideoControls, createUnsupportedSlide, createFoxitSlide, createSettingsButton } = AttachmentsUserInterface;
    const { handleSpotlightControlsOverride, handleMedia360Controls } = AttachmentsEventHandlers;

    const AttachmentTouchLayout = useAsyncComponent('o365.vue.components.Attachments.AttachmentTouchLayout.vue');
    const AttachmentPointerLayout = useAsyncComponent('o365.vue.components.Attachments.AttachmentPointerLayout.vue');

    loadCdnStyle('spotlight/src/css/spotlight.css');

    const props = defineProps({
        // required to get the attachments data from.
        dataObject: {
            type: Object,
            required: true
        },
        // will apply the developer-specified filter function to the list of attachments.
        filterCallback: {
            type: Function,
            required: false
        },
        // will put all items on the page of the same group together in one carousel.
        groupName: {
            type: String,
            default: '',
            required: false
        },
        // displays a lighter (in weight) list-version of the component.
        lightVersion: {
            type: Boolean,
            default: false,
            required: false
        },
        // displays a larger version of the component.
        largeVersion: {
            type: Boolean,
            default: false,
            required: false
        },
        // override the width of a largeversion card.
        largeVersionItemWidth: {
            type: String,
            default: '100%',
            required: false
        },
        // enables or disables the ability to view documents in SpotLight (only images if false).
        documentViewer: {
            type: Boolean,
            default: true,
            required: false
        },
        // specifies whether or not the user can edit image attachments.
        imageEditor: {
            type: Boolean,
            default: false,
            required: false
        },
        // uses file names instead of 'Attachment x' or 'Image x'.
        showFileNames: {
            type: Boolean,
            default: false,
            required: false
        },
        // override the width of a card (not for mobile).
        attachmentWidth: {
            type: String,
            default: null,
            required: false
        },
        // Specify (an array of objects) that shows the user what the attachment is connected to.
        connections: {
            type: Array,
            default: null,
            required: false
        },
        // specify if the attachments are being used in offline mode to get correct item link (for offline apps).
        offline: {
            type: Boolean,
            default: false,
            required: false
        },
        // override the index used in file names.
        overrideIndex: {
            type: Number,
            default: 0,
            required: false
        },
        // Add customButtons to dropdown.
        customButtons: {
            type: Array,
            default: null,
            required: false
        },
        //add leaflet button under custom buttons
        geoLocationBtn:{
            type:Boolean,
            default:false,
            required:false   
        },
        geoLocationConfig:{
            type:Object,
            default:null,
            required: false
        }
    });


    const availableFields = [];
    props.dataObject.recordSource.fields.map(field => availableFields.push(field.name));
    const requiredFields = ['Created', 'CreatedBy', 'FileName', 'FileRef', 'PrimKey'];
    const missingFields = requiredFields.filter(field => availableFields.indexOf(field) === -1);

    if (missingFields.length > 0) {
        console.warn(`Missing required fields on data object: ${JSON.stringify(missingFields)}`);
    }

    if (props.lightVersion && props.largeVersion) {
        console.warn(`Props 'lightVersion' and 'largeVersion' have both been enabled, but 'lightVersion' will be prioritized`);
    }

    const DISPLAYMODES = {
        NORMAL: 'displaymode_normal',
        MEDIA360: 'displaymode_media360'
    };

    let media360Scene = null;
    const media360Camera = ref(null);
    const media360Renderer = ref(null);
    const media360TargetContainer = ref(null);
    const media360DummyVideoElement = ref(null);
    const media360InteractionVariables = ref({
        lon: 0,
        lat: 0,
        pointerX: null,
        pointerY: null,
        pointerDownLon: null,
        pointerDownLat: null,
        isPointerDown: false,
        isPinching: false,
        lastPinchDistance: null
    });

    const isXs = ref(false);
    const isTouch = ref(false);
    const isMobile = ref(false);

    const isLarge = computed(() => props.largeVersion || (isTouch.value && props.connections));

    const isEditing = ref(false);
    const displayMode = ref(DISPLAYMODES.NORMAL);

    interface IOptions {
        fit?: string,
        index?: number,
        class?: string,
        autohide?: string,
        onshow?: Function,
        onchange?: Function,
        onclose?: Function,
    }

    const options: IOptions = { fit: 'contain', autohide: 'false', index: 0, class: '' };

    const imageEditorRef = ref(null);
    const editButton = ref(null);
    const downloadButton = ref(null);
    const settingsButton = ref(null);
    const toggle360Button = ref(null);
    const toggleImageButton = ref(null);
    const currentSpotlightItemOptions = ref(null);
    const initialized = ref(false);
    const appIdOverride = ref("");
    const databaseIdOverride = ref("");
    const objectStoreIdOverride = ref("");

    const attachmentsOuterContainerClass = computed(() => {
        if (isTouch.value) {
            if (props.lightVersion) {
                return 'list-group';
            } else if (isLarge.value) {
                return 'row gy-2 gx-2';
            } else {
                return 'mt-3 mb-3 d-flex gap-3 flex-wrap';
            }
        } else {
            if (props.lightVersion) {
                return '';
            } else {
                return 'd-flex flex-wrap';
            }
        }
    });

    const attachmentsInnerContainerClass = computed(() => {
        if (isTouch.value) {
            if (props.lightVersion) {
                return 'd-flex list-group-item justify-content-between align-items-center py-3';
            } else if (isLarge.value) {
                return 'col-12 col-md-6';
                //return 'border rounded p-2 mb-3';
            }
        } else {
            if (props.lightVersion) {
                return 'd-flex list-group-item align-items-center py-2';
            } else if (props.largeVersion) {
                return 'border rounded p-2 mb-3';
            } else {
                return 'mb-3 me-3';
            }
        }
    });

    const attachmentsInnerContainerStyle = computed(() => {
        if (isTouch.value) {
            if (props.lightVersion) {
                return '';
            } else if (props.largeVersion) {
                // return `
                    // width: ${props.largeVersionItemWidth};
                    // height: 240px;
                // `;
                return ``;
            } else {
                return 'min-width: 0;';
            }
        } else {
            if (props.lightVersion) {
                return '';
            } else if (props.largeVersion) {
                return `
                    width: ${props.largeVersionItemWidth};
                    height: 240px;
                `;
            } else {
                return `
                    width: ${props.attachmentWidth ?? '200px'};
                    aspect-ratio: 5 / 3;
                `;
            }
        }
    });

    const fileRecords = computed(() => {
        if (props.filterCallback) {
            return props.dataObject.data.filter(props.filterCallback); 
        }

        return props.dataObject.data;
    });

    const isMedia = (fileName) => {
        return isImage(getExtensionFromFileName(fileName)) || isVideo(getExtensionFromFileName(fileName));
    }

    const hasPDFViewerSupport = () => {
        let hasSupport = false;

        if (navigator && typeof navigator.pdfViewerEnabled === 'boolean') {
            hasSupport = navigator.pdfViewerEnabled;
        } else if (navigator && navigator.mimeTypes && navigator.mimeTypes['application/pdf'] && navigator.mimeTypes['text/pdf']) {
            hasSupport = true;
        }

        return hasSupport;
    }

    const getLink = (row, isDownload = false, originalFile = false) => {
        if (props.offline) {
            return getOfflineLink(row, isDownload, originalFile);
        }

        const { PrimKey, FileName } = row;

        let urlBase = '/nt/api';

        if (isMedia(FileName) || originalFile) {
            urlBase = '/nt/api/file';
        }
        
        const viewName = props.dataObject.viewName;

        let fileOrigin = '';

        if (isMedia(FileName)) {
            fileOrigin = isDownload ? 'download' : 'view';
        } else {
            if (isDownload) {
                fileOrigin = originalFile ? 'download' : 'download-pdf';
            } else {
                fileOrigin = 'view-pdf';
            }
        }

        return [urlBase, fileOrigin, viewName, PrimKey, safeEscapeFileName(FileName)].join('/');
    }

    const safeEscapeFileName = (pFileName:string)=>{
        if(pFileName.indexOf("+") > -1 ){
            return null;
        }else{
            return encodeURIComponent(pFileName)
        }
    }

    const getOfflineLink = (row, isDownload, originalFile) => {
        if(!initialized.value) return "";
        
        const { PrimKey, FileName } = row;
        
        let urlBase = '/nt/pwa/api';

        if (isMedia(FileName) || originalFile) {
            urlBase = '/nt/pwa/api/file';
        }

        let fileOrigin = '';

        if (isMedia(FileName)) {
            fileOrigin = isDownload ? 'download' : 'view';
        } else {
            if (isDownload) {
                fileOrigin = originalFile ? 'download' : 'download-pdf';
            } else {
                fileOrigin = 'view-pdf';
            }
        }

        const dataObject = props.dataObject;

        const appId = appIdOverride.value ?? app.id;
        const databaseId = databaseIdOverride.value ?? "DEFAULT";
        const objectStoreId = objectStoreIdOverride.value ?? dataObject.id;


        return `${urlBase}/${fileOrigin}/${appId}/${databaseId}/${objectStoreId}/${PrimKey}/${FileName}`;
    }

    const triggerDownload = async (linkItem = null, isOriginal = false) => {
        const PrimKey = linkItem === null ? currentSpotlightItemOptions.value.primKey : linkItem.PrimKey;
        const FileName = linkItem === null ? getFileNameFromUrl(currentSpotlightItemOptions.value.id) : linkItem.FileName;

        window.open(getLink({ PrimKey, FileName }, true, isOriginal), '_blank');
    }

    const getIconClasses = (fileName) => {
        return isImage(fileName) ? '' : getFileIconClasses(getExtensionFromFileName(fileName));
    }

    const openImageEditorByPrimKey = (PrimKey = null) => {
        if (PrimKey === null && !currentSpotlightItemOptions.value) {
            return;
        } else if (currentSpotlightItemOptions.value) {
            PrimKey = currentSpotlightItemOptions.value.primKey;
        }

        props.dataObject.setCurrentIndexByPrimKey(PrimKey);
        imageEditorRef.value.editorModal.show();
    }

    const toggleOptimizedImageSrc = (stateOverride = null) => {
        if (displayMode.value !== DISPLAYMODES.NORMAL) return null;

        const currentImg = document.querySelector('.O365_SpotlightSlideCustomPosition').querySelector('img');

        if (stateOverride === null) {
            if (currentImg.src.includes('&scale=original')) {
                currentImg.src = currentImg.src.replace('&scale=original', '');
                return true;
            }
            
            currentImg.src = `${currentImg.src}&scale=original`;
            return false;
        } else {
            if (stateOverride === true) {
                currentImg.src = currentImg.src.replace('&scale=original', '');
            } else {
                currentImg.src = `${currentImg.src}&scale=original`;
            }

            return stateOverride;
        }
    }

    const initMedia360 = () => {
        if (media360TargetContainer.value === null) {
            return;
        }

        const isMedia360Image = isImage(getFileNameFromUrl(currentSpotlightItemOptions.value.id));

        if (displayMode.value === DISPLAYMODES.MEDIA360) {
            if (isMedia360Image) {
                new THREE.TextureLoader().load(currentSpotlightItemOptions.value.id, (texture) => {
                    const overlay = media360TargetContainer.value?.querySelector('.overlay');

                    if (overlay) {
                        media360TargetContainer.value.removeChild(overlay);
                    }

                    initMedia360Scene(texture);
                });
            } else {
                media360DummyVideoElement.value.onloadeddata = () => {
                    const overlay = media360TargetContainer.value?.querySelector('.overlay');

                    if (overlay) {
                        media360TargetContainer.value.removeChild(overlay);
                    }

                    initMedia360Scene(new THREE.VideoTexture(media360DummyVideoElement.value));
                    media360DummyVideoElement.value.play();
                }

                media360DummyVideoElement.value.src = currentSpotlightItemOptions.value.id;
            }
        } else {
            const container = document.createElement('div');
            container.classList.add('O365_SpotlightSlideCustomPosition');

            if (isMedia360Image) {
                const img = document.createElement('img');
                img.classList.add('w-100', 'contain');

                img.onload = () => {
                    const overlay = media360TargetContainer.value?.querySelector('.overlay');

                    if (overlay) {
                        media360TargetContainer.value.removeChild(overlay);
                    }
                }

                img.src = currentSpotlightItemOptions.value.id;

                container.appendChild(img);
            } else {
                const video = document.createElement('video');
                video.muted = true;
                video.autoplay = true;
                video.controls = false;
                video.classList.add('w-100');
                video.style.height = 'calc(100% - 55px)';

                video.onloadeddata = () => {
                    const overlay = media360TargetContainer.value?.querySelector('.overlay');

                    if (overlay) {
                        media360TargetContainer.value.removeChild(overlay);
                    }
                }

                const videoSource = document.createElement('source');
                videoSource.src = currentSpotlightItemOptions.value.id;
                video.appendChild(videoSource);

                videoSource.onerror = () => {
                    const unsupportedContainer = document.createElement('p');
                    unsupportedContainer.classList.add('text-center', 'fs-5', 'mx-5');
                    unsupportedContainer.style.color = '#000000';
                    const unsupportedText = document.createElement('strong');
                    unsupportedText.textContent = $t("Unfortunately, we can't display this video. Download here instead:");
                    unsupportedText.classList.add('me-2');
                    const unsupportedLink = document.createElement('a');
                    unsupportedLink.href = currentSpotlightItemOptions.value.id;
                    unsupportedLink.textContent = getFileNameFromUrl(currentSpotlightItemOptions.value.id);

                    settingsButton.value?.setAttribute('style', 'display: none !important');

                    unsupportedContainer.appendChild(unsupportedText);
                    unsupportedContainer.appendChild(unsupportedLink);
                    
                    while (container.firstChild) {
                        container.removeChild(container.lastChild);
                    }

                    const overlay = media360TargetContainer.value?.querySelector('.overlay');

                    if (overlay) {
                        media360TargetContainer.value.removeChild(overlay);
                    }

                    container.classList.add('d-flex', 'align-items-center');

                    container.appendChild(unsupportedContainer);
                }

                container.appendChild(video);
                container.appendChild(createVideoControls(video));
            }

            media360TargetContainer.value.appendChild(container);
        }

        // Issues with SpotLight images being of type "node" (and in containers) when trying to zoom and the pan the image
        // This is fixed by triggering SpotLight to update its viewport (by sending resize event to its listener on window)
        window.dispatchEvent(new Event('resize'));
    }

    const initMedia360Scene = (texture) => {
        const render = () => {
            if (displayMode.value === DISPLAYMODES.MEDIA360) {
                requestAnimationFrame(render);
            }

            const phi = THREE.MathUtils.degToRad(90 - media360InteractionVariables.value.lat);
            const theta = THREE.MathUtils.degToRad(media360InteractionVariables.value.lon);
            media360Camera.value.position.x = 100 * Math.sin(phi) * Math.cos(theta);
            media360Camera.value.position.y = 100 * Math.cos(phi);
            media360Camera.value.position.z = 100 * Math.sin(phi) * Math.sin(theta);
            
            media360Camera.value.lookAt(media360Scene.position);
            media360Renderer.value.render(media360Scene, media360Camera.value);
        }

        let { width, height } = media360TargetContainer.value.getBoundingClientRect();

        media360Camera.value = new THREE.PerspectiveCamera(70, width / height, 1, 1000);
        
        if (media360Scene === null) {
            media360Scene = new THREE.Scene();
        } else {
            for (let i = media360Scene.children.length - 1; i >= 0; i--) {
                if (media360Scene.children[i].type === "Mesh") {
                    const item = media360Scene.children[i];
                    item.geometry.dispose();
                    item.material.dispose();
                    media360Scene.remove(item);
                }
            }
        }

        const geometry = new THREE.SphereGeometry(500, 60, 40);
        const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.BackSide });
        
        const mesh = new THREE.Mesh(geometry, material);
        mesh.scale.x = -1;
        
        media360Scene.add(mesh);
        
        if (media360Renderer.value === null) {
            media360Renderer.value = new THREE.WebGLRenderer({ antialias: true });
        }

        if (!isImage(getFileNameFromUrl(currentSpotlightItemOptions.value.id))) {
            height = parseInt(height, 10) - 95;
        }

        media360Renderer.value.setSize(width, height);

        const container = document.createElement('div');
        container.classList.add('O365_SpotlightSlideCustomPosition');
        
        container.appendChild(media360Renderer.value.domElement);

        if (!isImage(getFileNameFromUrl(currentSpotlightItemOptions.value.id))) {
            container.appendChild(createVideoControls(media360DummyVideoElement.value));
        }

        media360TargetContainer.value.appendChild(container);
        
        render();
    }

    const refreshSpotlight = (hasSlideChanged = false) => {
        const slide: HTMLDivElement = document.querySelector(`div[data-uid="${currentSpotlightItemOptions.value.uid}"][data-spotlightslide]`);

        if (slide === null) {
            media360TargetContainer.value = null;
            return;
        }

        media360TargetContainer.value = slide;

        const clearSlide = () => {
            while (slide.firstChild) {
                slide.removeChild(slide.lastChild);
            }

            slide.appendChild(createLoadingOverlay());
        }

        // Waiting for the slide to be done transforming so the 360 viewer can have the correct dimensions
        if (hasSlideChanged) {
            slide.ontransitionend = (e) => {
                if (e.propertyName === 'transform') {
                    clearSlide();
                    initMedia360();
                    slide.ontransitionend = null;
                }
            }
        } else {
            clearSlide();
            initMedia360();
        }
    }

    const updateMenuBarOpacity = (opacity) => {
        settingsButton.value.style.opacity = opacity;
    }
    
    const showSpotlight = async (e) => {
        const baseItemSelector = 'a[data-spotlightitem]';
        // Selecting elements with the same groupname
        const groupSelector = `${baseItemSelector}[data-groupname="${props.groupName}"]`;

        const closestItem = e.target.closest(baseItemSelector);
        //@ts-ignore
        const groupedSpotlightItems = [...document.querySelectorAll(groupSelector)];

        if (!closestItem || groupedSpotlightItems.length < 1) {
            return;
        }

        if (!props.documentViewer && !isMedia(getFileNameFromUrl(closestItem.href))) {
            return;
        }

        e.preventDefault();

        const spotlightGallery = [];
        groupedSpotlightItems.forEach(item => {
            // Give items a 'good enough' unique ID to match clicked item with current slide
            const uniqueID = Math.random().toString(16).slice(2);
            const fileExtension = getExtensionFromFileName(getFileNameFromUrl(item.href));

            item.dataset['uid'] = uniqueID;
            
            // The rendering of image vs. video has been moved out of SpotLight so that we can easily switch
            // between showing the normal viewers or the 360 degree variants ("moved out" means that SpotLight
            // isn't handling them like normal, instead we use the media: node type)
            if (isVideo(fileExtension) || isImage(fileExtension)) {
                const isMediaImage = isImage(fileExtension);

                spotlightGallery.push({
                    media: 'node',
                    id: item.href,
                    uid: uniqueID,
                    primKey: item.dataset['primkey'],
                    isImage: isMediaImage,
                    autofit: false,
                    'zoom-in': isMediaImage,
                    'zoom-out': isMediaImage,
                    src: (function () {
                        const container = document.createElement('div');
                        container.id = 'media360Container';
                        container.dataset['uid'] = uniqueID;
                        container.dataset['spotlightslide'] = '';
                        container.classList.add('w-100', 'h-100', 'd-flex', 'align-items-center', 'justify-content-center');
                        
                        container.appendChild(createLoadingOverlay());

                        return container;
                    })()
                });
            }

            // Some form of text document we want to render as PDF, but only if it is enabled and supported
            if (props.documentViewer && !isImage(fileExtension) && !isVideo(fileExtension)) {
                const documentSrc = () => {
                    const pdfSupport = hasPDFViewerSupport();
                    const fileName = getFileNameFromUrl(item.href);
                    const originalFileType = getExtensionFromFileName(fileName);
                    
                    const container = document.createElement('div');
                    const iframe = document.createElement('iframe');
                    // Make PDF controls visible under spotlight controls with custom positioning
                    iframe.classList.add('w-100', 'O365_SpotlightSlideCustomPosition');
                    
                    container.appendChild(createLoadingOverlay());

                    if (pdfSupport) {
                        iframe.onload = () => {
                            removeOverlay(container);

                            const unsupportedSlideArgs = [item, fileName, originalFileType, triggerDownload, container];

                            const entries = window.performance.getEntries();
                            const navigationData: any = entries?.find(e => e.entryType === "navigation");

                            if (navigationData?.responseStatus && navigationData?.responseStatus !== 0 && navigationData?.responseStatus >= 400) {
                                const status = parseInt(navigationData.responseStatus, 10);
                                
                                container.removeChild(iframe);

                                if (status >= 400 && status < 500) {
                                    unsupportedSlideArgs.unshift($t("Unfortunately, this file doesn't exist or you don't have access to it"));
                                    createUnsupportedSlide(...unsupportedSlideArgs);
                                } else if (status >= 500 && status < 600) {
                                    unsupportedSlideArgs.unshift($t('Server error. Please contact support if the problem persists'));
                                    createUnsupportedSlide(...unsupportedSlideArgs);
                                }
                            } else {
                                const documentContent = iframe.contentDocument || iframe.contentWindow.document;
                                const preTag = documentContent.body.querySelector('pre');

                                if (preTag) {
                                    container.removeChild(iframe);

                                    if (JSON.parse(preTag.textContent)?.detail?.toLowerCase().includes('extension')) {
                                        unsupportedSlideArgs.unshift(`${$t("Unfortunately, we're unable to show")} .${originalFileType} ${$t('files')}`);
                                        createUnsupportedSlide(...unsupportedSlideArgs);
                                    } else {
                                        unsupportedSlideArgs.unshift($t('An unknown error has occurred while trying to preview the file'));
                                        createUnsupportedSlide(...unsupportedSlideArgs);
                                    }
                                }
                            }
                        }
                        
                        iframe.src = item.href;

                        container.classList.add('w-100', 'h-100');
                        container.appendChild(iframe);
                    } else {
                        createFoxitSlide(item, props.dataObject.viewName, iframe, container);
                    }

                    return container;
                }

                spotlightGallery.push({
                    media: 'node',
                    id: item.href,
                    uid: uniqueID,
                    primKey: item.dataset['primkey'],
                    isImage: isImage(fileExtension),
                    autofit: false,
                    'zoom-in': false,
                    'zoom-out': false,
                    src: (documentSrc())
                });
            }
        });

        if (spotlightGallery.length < 1) return;

        SpotLight.theme("white");
        SpotLight.init();

        // Set the currently shown slide to clicked item
        options.index = spotlightGallery.map(x => x.uid).indexOf(e.target.closest(baseItemSelector).dataset['uid']) + 1;

        options.onchange = (newIndex, newOptions) => {
            currentSpotlightItemOptions.value = newOptions;
            
            editButton.value?.setAttribute('style', `display:${newOptions.isImage ? '' : 'none'} !important`);
            settingsButton.value?.setAttribute('style', `display:${isMedia(getFileNameFromUrl(newOptions.id)) ? '' : 'none'} !important`);
            toggleImageButton.value?.setAttribute('style', `display:${isImage(getFileNameFromUrl(newOptions.id)) ? '' : 'none'} !important`);

            displayMode.value = DISPLAYMODES.NORMAL;
            toggle360Button.value.innerHTML = $t('Turn on 360&deg; mode');

            try {
                toggleOptimizedImageSrc(true);
                toggleImageButton.value.textContent = $t('Show original image');
            } catch(e) {}

            refreshSpotlight(true);
        }

        options.onclose = () => {
            SpotLight.removeControl('O365_edit');
            SpotLight.removeControl('O365_download');
            SpotLight.removeControl('O365_settings');
            displayMode.value = DISPLAYMODES.NORMAL;
            document.querySelector('#O365_SpotlightBackdrop').classList.add('d-none');

            ['mousedown', 'touchstart', 'mousemove', 'touchmove', 'mouseup', 'touchend', 'resize', 'wheel'].forEach((evt) => {
                window.removeEventListener(evt, handleMedia360Controls, true);
            });

            ['keydown', 'wheel'].forEach((evt) => {
                window.removeEventListener(evt, handleSpotlightControlsOverride, true);
            });

            document.removeEventListener('shown.bs.dropdown', updateMenuBarOpacity);
            document.removeEventListener('hidden.bs.dropdown', updateMenuBarOpacity);
        }

        options.onshow = () => {
            const backdrop = document.querySelector('#O365_SpotlightBackdrop');
            if (backdrop) backdrop.classList.remove('d-none');

            // Override overlapping event listeners from SpotLight when image editing is active to avoid triggering them when editing
            ['keydown', 'wheel'].forEach((evt) => {
                window.addEventListener(evt, handleSpotlightControlsOverride.bind(null, isEditing), true);
            });

            // Override the events needed to control the 360 degree viewer and hand back control to SpotLight after
            ['mousedown', 'touchstart', 'mousemove', 'touchmove', 'mouseup', 'touchend', 'resize', 'wheel'].forEach((evt) => {
                const args = [displayMode, DISPLAYMODES, media360InteractionVariables, media360Camera, media360Renderer, media360TargetContainer, isImage, getFileNameFromUrl, currentSpotlightItemOptions];
                window.addEventListener(evt, handleMedia360Controls.bind(null, ...args), true);
            });

            document.addEventListener('shown.bs.dropdown', () => updateMenuBarOpacity(1));
            document.addEventListener('hidden.bs.dropdown', () => updateMenuBarOpacity(''));
        }

        downloadButton.value = SpotLight.addControl('O365_download', function() {
            if (!currentSpotlightItemOptions.value) {
                return;
            }

            triggerDownload(null, true);
        });

        downloadButton.value.classList.add('bi', 'bi-download', 'fs-5', 'd-flex', 'justify-content-center', 'align-items-center', 'spl-O365_button');

        if (props.imageEditor) {
            editButton.value = SpotLight.addControl('O365_edit', () => openImageEditorByPrimKey());

            editButton.value.classList.add('bi', 'bi-pencil-square', 'fs-5', 'd-flex', 'justify-content-center', 'align-items-center', 'spl-O365_button');
        }

        settingsButton.value = SpotLight.addControl('O365_settings');
        settingsButton.value.classList.add('dropdown', 'd-flex', 'justify-content-center', 'align-items-center', 'spl-O365_button', 'fs-5');
        createSettingsButton(displayMode, DISPLAYMODES, toggle360Button, toggleImageButton, settingsButton, refreshSpotlight, toggleOptimizedImageSrc);

        SpotLight.show(spotlightGallery, options);

        await nextTick();
        
        if (!document.querySelector('#O365_SpotlightBackdrop')) {
            const spl: HTMLDivElement = document.querySelector('#spotlight');

            if (!spl) return;

            // Append a backdrop to body that can close Spotlight
            const backdrop = document.createElement('div');

            backdrop.id = 'O365_SpotlightBackdrop';
            backdrop.style.background = 'rgba(0, 0, 0, 0.5)';
            backdrop.style.inset = '0';
            backdrop.classList.add('position-fixed');
            backdrop.addEventListener('click', () => SpotLight.close());
            
            document.body.appendChild(backdrop);

            // Make sure that things like modals can appear over top of the spotlight by setting z-index lower than 99999
            backdrop.style.zIndex = '1050';
            spl.style.zIndex = '1051';
        }
    }

    const openImageEditor = () => {
        isEditing.value = true;
    }

    const closedImageEditor = () => {
        isEditing.value = false;
        const img = document.querySelector('#media360Container')?.querySelector('img');

        if (img) {
            const { PrimKey, FileName, Updated } = props.dataObject.current;
            img.src = `${getLink({ PrimKey, FileName })}?updated=${Updated}`;
        }
    }

    const initalize = async () => {
        if (!props.offline) {
            return;
        }

        await import('o365.dataObject.extension.Offline.ts');

        const dataObject = await props.dataObject.offline;

        appIdOverride.value = await dataObject.appIdOverride;
        databaseIdOverride.value = await dataObject.databaseIdOverride;
        objectStoreIdOverride.value = await dataObject.objectStoreIdOverride;
        initialized.value = true;
    }

    const updateQueries = () => {
        isXs.value = MediaQueryManager.getQueryMatchState('xs');

        // isTouch doesn't work on all browsers, but isMobile should at least work on all mobiles
        isTouch.value = MediaQueryManager.getQueryMatchState('isTouch') || MediaQueryManager.getQueryMatchState('isMobile');
        
        isMobile.value = MediaQueryManager.getQueryMatchState("isMobile");
    }

    onMounted(() => {
        MediaQueryManager.on('QueryChanged', updateQueries);
        initalize();
        updateQueries();
    });

    onBeforeUnmount(() => {
        MediaQueryManager.off('QueryChanged', updateQueries);
    });


    const [capturedError, ErrorRenderer] = useErrorCapture({
        consoleMessagee: `Error encountered when trying to render Attachments`,
        errorRenderFunctionOptions: {
            uiMessage: 'Attachments Render Error',
            uiTitleMessage: 'An error has occurred when rendering the attachments',
            type: 'span'
        }
    });

    provide('O365_AttachmentGetLink', getLink);
    provide('O365_AttachmentIsImage', isImage);
    provide('O365_AttachmentGetIconClasses', getIconClasses);
    provide('O365_AttachmentTriggerDownload', triggerDownload);
    provide('O365_AttachmentOpenImageEditorByPrimKey', openImageEditorByPrimKey);
</script>

<template v-if="(props.offline && initialized) || !props.offline">
    <ErrorRenderer v-if="capturedError"/>

    <div v-if="fileRecords.length" :class="attachmentsOuterContainerClass" @click="showSpotlight">
        <div v-for="(row, i) in fileRecords" :class="attachmentsInnerContainerClass" :style="attachmentsInnerContainerStyle" :key="row.PrimKey">
            <AttachmentTouchLayout v-if="isTouch"
                :row="row"
                :index="overrideIndex || i"
                :groupName="groupName"
                :lightVersion="lightVersion"
                :largeVersion="isLarge"
                :documentViewer="documentViewer"
                :imageEditor="imageEditor"
                :showFileNames="showFileNames"
                :connections="connections"
                :customButtons="customButtons"
                :geoLocationBtn="geoLocationBtn"
                :geoLocationConfig="geoLocationConfig" />

            <AttachmentPointerLayout v-else
                :row="row"
                :index="overrideIndex || i"
                :groupName="groupName"
                :lightVersion="lightVersion"
                :largeVersion="lightVersion && largeVersion ? false : largeVersion"
                :documentViewer="documentViewer"
                :imageEditor="imageEditor"
                :showFileNames="showFileNames"
                :connections="connections"
                :customButtons="customButtons" 
                :geoLocationBtn="geoLocationBtn"
                :geoLocationConfig="geoLocationConfig" />
        </div>

        <video class="d-none" ref="media360DummyVideoElement" muted></video>

        <OImageEditor :dataObject="dataObject" ref="imageEditorRef" @open="openImageEditor" @close="closedImageEditor" :offline="props.offline" />
    </div>
</template>

<style>
    .O365_SpotlightSlideCustomPosition {
        position: absolute !important;
        bottom: 0 !important;
        height: calc(100% - 50px) !important;
    }

    .O365_SpotlightSlideVideoVolumeSliderToggle:hover > .O365_SpotlightSlideVideoVolumeSliderDropdown {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .spl-header {
        overflow: unset !important;
    }
    
    .spl-O365_button {
        filter: invert(0) drop-shadow(0 0 0 #000000) !important;
    }
</style>